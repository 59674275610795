<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="info" v-if="paramsId">
        <span class="mr-20">所属门店：{{form.shopName}}</span>
        <span class="mr-20">创建时间：{{form.createTime}}</span>
        <span class="mr-20">最后登录时间：{{form.loginDate}}</span>
        <span class="mr-20">最后登录IP：{{form.loginIp}}</span>
      </div>
      <div slot="content">
        <a-card title="用户信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="帐号" prop="loginName">
                  <a-input v-model="form.loginName" placeholder="请输入账号" :disabled="paramsId" v-if="!paramsId"></a-input>
                  <span v-else>{{form.loginName}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="用户角色" prop="roleId">
                  <a-select v-model="form.roleId" :disabled="isDetail" placeholder="请选择">
                    <a-select-option :value="item.roleId" v-for="item in roleList" :key="item.roleId">
                      {{item.roleName}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-if="!paramsId">
              <a-col :span="10">
                <a-form-model-item label="密码" prop="password">
                  <a-input v-model="form.password" type="password" placeholder="请输入密码" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="确认密码" prop="password1">
                  <a-input v-model="form.password1" type="password" placeholder="请输入确认密码" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="姓名" prop="userName">
                  <a-input v-model="form.userName" placeholder="请输入姓名" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="手机号" prop="mobile">
                  <a-input v-model="form.mobile" placeholder="请输入手机号" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="邮箱" prop="email">
                  <a-input v-model="form.email" placeholder="请输入手机号" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="性别" prop="sex">
                  <a-radio-group v-model="form.sex" :disabled="isDetail">
                    <a-radio :value="1">男</a-radio>
                    <a-radio :value="2">女</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>

              <a-col :span="10">
                <a-form-model-item label="备注" prop="desc">
                  <a-input v-model="form.remark" type="textarea" allowClear :maxLength="100" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { sysUserInfo, sysUserSubmit, selectRoleList, sysUserUpdate } from '@/api/user';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'userForm',
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  props: {},
  data() {
    const validatePass = (rule, value, callback) => {
      if (this.form.password1 && value !== this.form.password1) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (this.form.password && value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    return {
      roleList: [],
      form: {
        loginName: '',
        roleId: '',
        userName: '',
        mobile: '',
        email: '',
        sex: '',
        remark: ''
      },
      rules: {
        loginName: [
          {
            required: true,
            message: '请输入账号'
          }
        ],
        roleId: [
          {
            required: true,
            message: '请选择用户角色'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码'
          },
          { validator: validatePass, trigger: 'blur' }
        ],
        password1: [
          {
            required: true,
            message: '请输入确认密码'
          },
          { validator: validatePass2, trigger: 'blur' }
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号'
          }
          // ,
          // {
          //   trigger: 'blur',
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: '请输入正确的手机号'
          // }
        ],
        userName: [
          {
            required: true,
            message: '请输入姓名'
          }
        ],
        email: [
          {
            trigger: 'blur',
            type: 'email',
            message: '请输入正确的邮箱'
          }
        ],
      },
      infoList: [],
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    }
  },
  watch: {},
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
    this.getRoleList();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 详情信息
    getData(id) {
      sysUserInfo(id).then((data) => {
        this.form = data;
      });
    },

    getRoleList() {
      selectRoleList().then((data) => {
        this.roleList = data;
      });
    },

    // 保存
    handleSave() {
      const params = { ...this.form };
      const finalAction = this.paramsId ? sysUserUpdate : sysUserSubmit;
      finalAction(params).then(() => {
        this.$message.success('操作成功！');
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
